import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var __jsx = React.createElement;
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { valueForPlatform } from '~/common/Helpers';
import Localize from '~/common/Localize';
import LottieView from '~/components/LottieView';
import MainButton, { MainButtonType } from '~/components/MainButton';
import { Body3, Container, InfoType, Theme, themed, Title1 } from '~/styles/Theme';
var StatusMessage = function StatusMessage(props) {
  var infoType = props.infoType,
    title = props.title,
    info = props.info,
    onCalendarPress = props.onCalendarPress,
    onPress = props.onPress,
    testID = props.testID,
    buttonText = props.buttonText;
  var shouldAddCalendar = function shouldAddCalendar() {
    return valueForPlatform({
      web: false,
      "native": props.showCalendarButton && !props.isAddedToCalendar
    });
  };
  return __jsx(MainContainer, {
    testID: testID
  }, __jsx(MiddleView, null, __jsx(StatusIcon, {
    infoType: infoType
  }), __jsx(Title, {
    infoType: infoType
  }, title), __jsx(Message, null, info)), __jsx(BottomView, null, shouldAddCalendar() ? __jsx(Calendar, {
    onPress: onCalendarPress
  }) : null, __jsx(MainButton, {
    type: MainButtonType.footer,
    infoType: infoType,
    onPress: onPress,
    testID: "".concat(testID, "Button")
  }, buttonText)));
};
export default StatusMessage;
var Calendar = function Calendar(props) {
  return __jsx(View, {
    style: {
      paddingBottom: 10
    },
    testID: "addToCalendarButton"
  }, __jsx(MainButton, {
    type: MainButtonType.footer,
    infoType: InfoType.addNote,
    onPress: props.onPress
  }, __jsx(Title1, {
    style: {
      color: '#505E75'
    }
  }, Localize('booking.calendar'))));
};
export var StatusIcon = function StatusIcon(props) {
  switch (props.infoType) {
    case InfoType.success:
      return __jsx(StatusAnimation, {
        source: require('~/../resources/animations/common/success.json')
      });
    case InfoType.error:
      return __jsx(StatusAnimation, {
        source: require('~/../resources/animations/common/error.json')
      });
    case InfoType.warning:
      return __jsx(StatusAnimation, {
        source: require('~/../resources/animations/common/warning.json')
      });
  }
  return null;
};

// eslint-disable-next-line
var StatusAnimation = function StatusAnimation(props) {
  var _useState = useState(false),
    shouldAnimate = _useState[0],
    setShouldAnimate = _useState[1];
  var animationRef = useRef(null);
  useEffect(function () {
    if (shouldAnimate) return;
    setShouldAnimate(true);
    var timeout = setTimeout(function () {
      setShouldAnimate(false);
    }, 1);
    return function () {
      clearTimeout(timeout);
    };
  });
  useEffect(function () {
    var _animationRef$current, _animationRef$current2, _animationRef$current3, _animationRef$current4;
    if (!shouldAnimate || !animationRef.current) return;
    (_animationRef$current = animationRef.current) === null || _animationRef$current === void 0 || (_animationRef$current2 = _animationRef$current.reset) === null || _animationRef$current2 === void 0 || _animationRef$current2.call(_animationRef$current);
    (_animationRef$current3 = animationRef.current) === null || _animationRef$current3 === void 0 || (_animationRef$current4 = _animationRef$current3.play) === null || _animationRef$current4 === void 0 || _animationRef$current4.call(_animationRef$current3);
  }, [shouldAnimate]);
  return __jsx(LottieView, {
    ref: animationRef,
    source: props.source,
    autoPlay: true,
    loop: false,
    style: {
      height: 335,
      width: 300,
      alignSelf: 'center'
    }
  });
};
var MainContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  justifyContent: center\n  alignItems: center\n"])));
var MiddleView = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  justifyContent: center\n  alignItems: center\n"])));
var BottomView = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 20px\n  align-items: center\n  justifyContent: center\n"])));
var Title = themed(Title1)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  padding-bottom: 10px\n  padding-top: 20px\n"])), function (props) {
  return props.infoType === InfoType.success && "color: ".concat(Theme(props).colors.success);
}, function (props) {
  return props.infoType === InfoType.warning && "color: ".concat(Theme(props).colors.warning);
}, function (props) {
  return props.infoType === InfoType.error && "color: ".concat(Theme(props).colors.error);
}, function (props) {
  return props.infoType === InfoType.info && "color: ".concat(Theme(props).colors.info);
});
var Message = themed(Body3)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", ";\n  margin-left: 30px\n  margin-right: 30px\n  text-align: center\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.neutral8);
});