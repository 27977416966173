export var HardwareBackHandler = {
  addEventListener: function addEventListener() {
    return {
      remove: function remove() {
        // NO OP
      }
    };
  },
  removeEventListener: function removeEventListener() {
    // NO OP
  },
  exitApp: function exitApp() {
    // NO OP
  }
};