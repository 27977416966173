import React from "react";
var __jsx = React.createElement;
import { useRouter } from 'solito/router';
import Localize from '~/common/Localize';
import { FeedbackSource } from '~/components/Feedback';
import StatusMessage from '~/components/StatusMessage';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { InfoType } from '~/styles/Theme';
import { CLIENT_COURSES_FROM } from '../account/clientCourses/ClientCoursesScreen';
export default function FeedbackSuccess(props) {
  var _useRouter = useRouter(),
    back = _useRouter.back,
    replace = _useRouter.replace;
  function onClose() {
    switch (props.source) {
      case FeedbackSource.CheckOut:
        replace(getRouteUrl(RouteName.Appointments));
        break;
      case FeedbackSource.Purchase:
        replace(getRouteUrl(RouteName.ClientCourses, {
          from: CLIENT_COURSES_FROM.Purchase
        }));
        break;
      case FeedbackSource.FAB:
        if (props.onModalClose) {
          props.onModalClose();
        }
        break;
      default:
        back();
    }
  }
  return __jsx(StatusMessage, {
    infoType: InfoType.success,
    title: Localize('feedback.successTitle'),
    info: Localize('feedback.successInfo'),
    onPress: onClose,
    testID: "feedbackSuccess",
    buttonText: Localize('global.close')
  });
}