import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject;
import React from "react";
var __jsx = React.createElement;
import { Container, SubTitle2, themed } from '~/styles/Theme';
var EmptyCell = function EmptyCell(props) {
  return __jsx(ItemContainer, {
    testID: props.testID
  }, __jsx(SubTitle2, {
    style: {
      opacity: 0.8
    }
  }, props.info));
};
export default EmptyCell;
var ItemContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  flex: 1\n  justify-content: center\n  align-items: center\n  border-width: 1.5px\n  border-color: grey\n  border-style: dashed\n  align-items: center\n  borderRadius: 8px\n  max-height: 80px\n  margin-top: 10px\n  min-height: 80px\n  opacity: 0.7\n"])));