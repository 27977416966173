import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useSelector } from 'react-redux';
import { createParam } from 'solito';
import { RouteName } from '~/navigation/Routes';
import { useAppTheme } from '~/styles/Theme';
import { Localize } from '../Localize';
import { selectBranch } from '../slices/model.slice';
export function useMetaTags() {
  var _tags;
  var branch = useSelector(selectBranch);
  var theme = useAppTheme();
  var _createParam = createParam(),
    useParam = _createParam.useParam;
  var _useParam = useParam('title'),
    _useParam2 = _slicedToArray(_useParam, 1),
    serviceTitle = _useParam2[0];
  var _useParam3 = useParam('title'),
    _useParam4 = _slicedToArray(_useParam3, 1),
    serviceCategoryTitle = _useParam4[0];
  var url, origin, homeUrl;
  if (window != null && window.location != null) {
    url = window.location.href;
    origin = window.location.origin;
    homeUrl = "".concat(origin, "/salon/").concat(branch === null || branch === void 0 ? void 0 : branch.domainName);
  }
  var tags = (_tags = {}, _defineProperty(_tags, RouteName.Home, {
    title: Localize('bottomTab.home'),
    description: "".concat(Localize('metatags.home.description'), " ").concat(branch === null || branch === void 0 ? void 0 : branch.name),
    noindex: false,
    openGraph: {
      url: url,
      title: (branch === null || branch === void 0 ? void 0 : branch.name) || Localize('bottomTab.home'),
      description: "".concat(Localize('metatags.home.description'), " ").concat(branch === null || branch === void 0 ? void 0 : branch.name),
      images: [{
        url: theme.images.heroUrl,
        alt: branch === null || branch === void 0 ? void 0 : branch.name
      }]
    }
  }), _defineProperty(_tags, RouteName.AboutUs, {
    title: Localize('header.aboutUs'),
    description: "".concat(Localize('metatags.aboutUs.description'), " ").concat(branch === null || branch === void 0 ? void 0 : branch.name),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.ServiceDetails, {
    title: serviceTitle || "".concat(branch === null || branch === void 0 ? void 0 : branch.name, " ").concat(Localize('metatags.serviceDetails.title')),
    description: "".concat(Localize('metatags.serviceDetails.description'), " ").concat(serviceTitle),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.ServiceCategory, {
    title: serviceCategoryTitle || "".concat(branch === null || branch === void 0 ? void 0 : branch.name, " ").concat(Localize('metatags.serviceCategory.title')),
    description: "".concat(Localize('metatags.serviceCategory.description'), " ").concat(serviceCategoryTitle),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.CampaignLanding, {
    title: "".concat(branch === null || branch === void 0 ? void 0 : branch.name, " ").concat(Localize('metatags.campaign.title')),
    noindex: true,
    description: "".concat(Localize('metatags.campaign.description'), " ").concat(branch === null || branch === void 0 ? void 0 : branch.name)
  }), _defineProperty(_tags, RouteName.Account, {
    title: Localize('bottomTab.account'),
    description: Localize('metatags.account.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.Appointments, {
    title: Localize('metatags.myAppointments.title'),
    description: Localize('metatags.myAppointments.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.Availability, {
    title: Localize('metatags.availability.title'),
    description: Localize('metatags.availability.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.CheckOut, {
    title: Localize('metatags.checkout.title'),
    description: Localize('metatags.checkout.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.CheckOutReschedule, {
    title: Localize('metatags.reschedule.title'),
    description: Localize('metatags.reschedule.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.CheckOutError, {
    title: Localize('metatags.checkoutError.title'),
    description: Localize('metatags.checkoutError.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.CheckOutSuccess, {
    title: Localize('metatags.success.title'),
    description: Localize('metatags.success.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.CheckOutPurchaseSuccess, {
    title: Localize('metatags.success.title'),
    description: Localize('metatags.success.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.ClientCourses, {
    title: Localize('metatags.clientCourses.title'),
    description: Localize('metatags.clientCourses.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.ClientCourseSelection, {
    title: Localize('metatags.clientCourses.title'),
    description: Localize('metatags.clientCourses.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.CreateAccount, {
    title: Localize('metatags.createAccount.title'),
    description: Localize('metatags.createAccount.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.FAQ, {
    title: Localize('metatags.faq.title'),
    description: Localize('metatags.faq.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.Feedback, {
    title: Localize('metatags.feedback.title'),
    description: Localize('metatags.feedback.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.ForgotPassword, {
    title: Localize('metatags.forgotPassword.title'),
    description: Localize('metatags.forgotPassword.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.LinkedServices, {
    title: Localize('metatags.linkedServices.title'),
    description: Localize('metatags.linkedServices.description'),
    noindex: true
  }), _defineProperty(_tags, RouteName.Login, {
    title: Localize('metatags.login.title'),
    description: Localize('metatags.login.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.MultiServiceStaffSelection, {
    title: Localize('metatags.multiServiceStaffSelection.title'),
    description: Localize('metatags.multiServiceStaffSelection.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.ServiceGroups, {
    title: Localize('metatags.serviceGroups.title'),
    description: Localize('metatags.serviceGroups.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.Services, {
    title: Localize('metatags.services.title'),
    description: Localize('metatags.services.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.ServiceSelection, {
    title: Localize('metatags.serviceSelection.title'),
    description: Localize('metatags.serviceSelection.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.StaffProfile, {
    title: Localize('metatags.staffProfile.title'),
    description: Localize('metatags.staffProfile.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.StaffSelection, {
    title: Localize('metatags.staffSelection.title'),
    description: Localize('metatags.staffSelection.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.UserAccountSettings, {
    title: Localize('metatags.userAccountSettings.title'),
    description: Localize('metatags.userAccountSettings.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.Locations, {
    title: Localize('metatags.locations.title'),
    description: Localize('metatags.locations.description'),
    noindex: true,
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.Gallery, {
    title: Localize('metatags.gallery.title'),
    noindex: true,
    description: Localize('metatags.gallery.description')
  }), _defineProperty(_tags, RouteName.ReferralsProgram, {
    title: Localize('metatags.referralsProgram.title'),
    noindex: true,
    description: Localize('metatags.referralsProgram.description')
  }), _defineProperty(_tags, RouteName.ReferralsLanding, {
    title: Localize('metatags.referralsLanding.title'),
    noindex: true,
    description: Localize('metatags.referralsLanding.description')
  }), _defineProperty(_tags, RouteName.ReferralsLandingPreview, {
    title: Localize('metatags.referralsLandingPreview.title'),
    noindex: true,
    description: Localize('metatags.referralsLandingPreview.description')
  }), _defineProperty(_tags, RouteName.ConsultationsForm, {
    title: Localize('metatags.consultation.title'),
    noindex: true,
    description: Localize('metatags.consultation.description')
  }), _defineProperty(_tags, RouteName.PurchaseCourse, {
    title: Localize('metatags.course.title'),
    noindex: true,
    description: Localize('metatags.course.description'),
    canonical: homeUrl
  }), _defineProperty(_tags, RouteName.PurchaseCheckout, {
    title: Localize('metatags.purchaseCheckout.title'),
    noindex: true,
    description: Localize('metatags.purchaseCheckout.description'),
    canonical: homeUrl
  }), _tags);
  function getPageMetaTags(page) {
    if (!page) {
      return {
        title: Localize('metatags.onlineBooking.title'),
        description: Localize('metatags.onlineBooking.description'),
        openGraph: undefined,
        noindex: true
      };
    }
    return tags[page];
  }
  return {
    tags: tags,
    getPageMetaTags: getPageMetaTags
  };
}
export default useMetaTags;