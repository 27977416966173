import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { isWeb } from '~/common/Env';
import { selectIsEmbedded } from '~/common/slices/app.slice';
import { Container, SubTitle1, Theme, themed, useAppTheme } from '~/styles/Theme';
export default function WebHeader(_ref) {
  var title = _ref.title,
    onBackPress = _ref.onBackPress,
    testId = _ref.testId,
    _ref$disabled = _ref.disabled,
    disabled = _ref$disabled === void 0 ? false : _ref$disabled;
  var theme = useAppTheme();
  var _useRouter = useRouter(),
    back = _useRouter.back;
  var isEmbedded = useSelector(selectIsEmbedded);
  function onPress() {
    if (onBackPress) {
      onBackPress();
    } else {
      back();
    }
  }
  if (isEmbedded || !isWeb()) {
    return null;
  }
  return __jsx(HeaderContainer, {
    testID: testId
  }, !disabled && __jsx(BackButton, {
    onPress: onPress,
    testID: "webHeaderBackButton",
    disabled: disabled
  }, __jsx(Icon, {
    name: "chevron-left",
    color: theme.colors.neutral7,
    size: 26
  })), __jsx(SubTitle1, {
    style: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    testID: 'webHeaderSubTitle'
  }, title));
}
var HeaderContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  flexDirection: row\n  alignItems: center\n  padding: 16px\n  border-top-left-radius: 4px\n  border-top-right-radius: 4px\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
});
var BackButton = themed(Pressable)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 4px\n  margin: 8px\n  position: absolute\n  left: 8px\n"])));